import { paletteColorIds } from '@blissbook/lib/blissbook/branding'
import { immerable } from 'immer'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import omit from 'lodash/omit'
import TinyColor from 'tinycolor2'
import { formatGoogleUrl } from '../google/font'
import { getContrastColor } from './color'
import { scopeCss } from './custom-css'
import Font from './font'
import { FONT_SETTINGS } from './settings'

export const getColorClass = (id) => `brand-${id}`
export const getBackgroundColorClass = (id) => getColorClass(id) + '-background'

export const renderBodyFontCss = (font) => `
  .blissbook-branded {
    font-family: ${font.familyStack};
    font-weight: ${font.weights.normal};
  }
  .blissbook-branded strong {
    font-weight: ${font.weights.bold || 'bold'};
  }
`

export const renderHeadingFontCss = (font) => `
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-family: ${font.familyStack};
    font-weight: ${font.weights.normal};
  }
`

export const renderFontFaceCss = (font) => `
  @font-face {
    font-family: "${font.family}";
    font-weight: normal;
    font-style: normal;
    src: url("${font.url}.eot");
    src: url("${font.url}.eot?#iefix") format("embedded-opentype"),
    url("${font.url}.woff") format("woff"),
    url("${font.url}.ttf") format("truetype"),
    url("${font.url}.svg#${font.url}") format("svg");
  }
`

export const renderFontClassCss = (font) =>
  `.${font.setting.className} { font-family: ${font.familyStack}; font-weight: ${font.weights.normal}; }`

export default class Branding {
  static fromJSON(json) {
    return Object.assign(new Branding(), json)
  }

  toJSON() {
    // Omit fonts
    const fontKeys = FONT_SETTINGS.map((s) => s.id)
    const privateFontKeys = fontKeys.map((key) => `_${key}`)
    const json = omit(this, fontKeys, privateFontKeys)

    // Add fonts
    for (const key of fontKeys) {
      json[key] = this[key].toJSON()
    }

    return json
  }

  // COLOR --------------------------------------------------------------------

  getColor(value) {
    if (!value) return
    const color = this[`${value}Color`] || value
    return TinyColor(color).toHexString()
  }

  getContrastColor(value) {
    const color = this.getColor(value)
    const { blackColor, whiteColor } = this
    return getContrastColor(color, [blackColor, whiteColor])
  }

  getBackgroundColorStyle(value) {
    return {
      backgroundColor: this.getColor(value),
      color: this.getContrastColor(value),
    }
  }

  renderColorsCss() {
    return paletteColorIds
      .map((id) => {
        const color = this.getColor(id)
        const contrastColor = this.getContrastColor(color)
        const backgroundClass = getBackgroundColorClass(id)
        return `.${backgroundClass} { background: ${color}; color: ${contrastColor}; }`
      })
      .join('\n')
  }

  renderTemplateColorCss(template) {
    const { blackColor, primaryColor, whiteColor } = this
    const css = `section.text h3 { color: ${primaryColor}; }`
    if (template !== 'handbook') return css
    return `
      ${css}
      .brand-document h1 { color: ${primaryColor}; }
      .brand-document h2 { color: ${primaryColor}; }
      .brand-document h3 { color: ${primaryColor}; }
      .brand-document h4 { color: ${primaryColor}; }
      .brand-document h5 { color: ${primaryColor}; }
      .brand-document h6 { color: ${primaryColor}; }
      .brand-primary-text { color: ${primaryColor}; }
      section, .modal-content { background: ${whiteColor}; color: ${blackColor}; }
      rw-read-more .rw-read-more-box { background: ${primaryColor}; }
    `
  }

  // FONTS --------------------------------------------------------------------

  // Return all of the fonts
  get fonts() {
    return compact(FONT_SETTINGS.map((setting) => this[setting.id]))
  }

  // Getters
  get customFonts() {
    return this.fonts.filter((f) => f.type === 'custom')
  }

  get googleFonts() {
    return this.fonts.filter((f) => f.type === 'google')
  }

  get googleFontUrl() {
    return formatGoogleUrl(this.googleFonts)
  }

  // Load custom fonts
  loadCustomFonts(fonts) {
    this.customFonts.forEach((customFont) => {
      customFont._font = fonts.find((f) => f.id === customFont.fontId)
    })
  }

  // CSS ----------------------------------------------------------------------

  // Return array of generators
  renderCss(options = {}) {
    const { bodyFont, headingFont } = this
    const { fontFaceCss, template } = options
    const css = flatten([
      // Fonts
      bodyFont && renderBodyFontCss(bodyFont),
      headingFont && renderHeadingFontCss(headingFont),
      fontFaceCss || this.customFonts.map(renderFontFaceCss),
      this.fonts.map(renderFontClassCss),
      // Colors
      this.renderColorsCss(),
      this.renderTemplateColorCss(template),
      // Custom
      this[`${template}Css`],
    ]).join('\n')
    return scopeCss(css)
  }
}

Branding[immerable] = true

// Add font settings to prototype
FONT_SETTINGS.forEach((setting) => {
  const key = setting.id
  const privateKey = '_' + key
  Object.defineProperty(Branding.prototype, key, {
    enumerable: true,
    get: function () {
      return this[privateKey]
    },
    set: function (json) {
      this[privateKey] = Font.fromJSON(json, setting)
    },
  })
})
