import HandbookBranding from '@blissbook/common/branding'
import {
  type BlissbookHandbook,
  type BlissbookHandbookAcknowledgementForm,
  type BlissbookHandbookContent,
  type BlissbookSection,
  BlissbookToc,
} from '@blissbook/lib/blissbook'
import {
  type Node,
  createHandbookSectionNode,
  isNodeId,
} from '@blissbook/lib/document'
import type { HandbookAudience } from '@blissbook/lib/expression'
import { immerable } from 'immer'
import { FontDataModel, type FontDataModelInput } from '..'
import { HandbookSectionDataModel } from './section'
import type { HandbookTransaction } from './transaction'

export type HandbookDataModelJSON = BlissbookHandbook & {
  fonts?: FontDataModelInput[]
  sections?: Partial<BlissbookSection>[]
}

export class HandbookDataModel {
  acknowledgementForm: BlissbookHandbookAcknowledgementForm
  audience: HandbookAudience
  private _branding: IBlissbookHandbookBranding
  contactFragments: BlissbookHandbookContent
  content: Node[]
  defaultLanguageCode: string
  private _fonts: FontDataModel[]
  id: number
  name: string
  public: boolean
  publishedAt?: Date
  sectionsById: Map<number, HandbookSectionDataModel>
  wysiwyg?: string
  version: number

  // UI-only
  permissionIds: string[]

  // Create a handbook section from JSON
  static fromJSON({
    branding,
    fonts = [],
    sections = [],
    ...json
  }: HandbookDataModelJSON) {
    const sectionsById = new Map<number, HandbookSectionDataModel>()
    for (const json of sections) {
      const section = HandbookSectionDataModel.fromJSON(json)
      sectionsById.set(section.id, section)
    }

    return new HandbookDataModel({
      ...json,
      branding: HandbookBranding.fromJSON(branding),
      fonts: fonts.map(FontDataModel.fromJSON),
      sectionsById,
    })
  }

  constructor(
    json: BlissbookHandbook & {
      fonts: FontDataModel[]
      sectionsById: Map<number, HandbookSectionDataModel>
    },
  ) {
    Object.assign(this, json)
  }

  // Branding ------------------------------------------------------------------

  get branding() {
    return this._branding
  }

  get fonts() {
    return this._fonts
  }

  set branding(branding) {
    this._branding = branding
    this.loadBranding()
  }

  set fonts(fonts: FontDataModel[]) {
    this._fonts = fonts
    this.loadBranding()
  }

  private loadBranding() {
    const { branding, fonts } = this
    if (branding && fonts) {
      branding.loadCustomFonts(fonts)
    }
  }

  // Transactions -------------------------------------------------------------

  applyTransaction(tr: HandbookTransaction) {
    if (tr.type === 'addSection') {
      // Add to sections
      const { position, section } = tr.result
      this.sectionsById.set(section.id, section)

      // Add to content
      const index = Number.isInteger(position) ? position - 1 : undefined
      const node = createHandbookSectionNode(section)
      this.insertContent([node], index)
    } else if (tr.type === 'cloneSection') {
      // Add to sections
      const sections = tr.result.sections.map(HandbookSectionDataModel.fromJSON)
      for (const section of sections) {
        this.sectionsById.set(section.id, section)
      }

      // Add to content
      const index = tr.result.position - 1
      const nodes = sections.map(createHandbookSectionNode)
      this.content.splice(index, 0, ...nodes)
    } else if (tr.type === 'convertSectionToPolicy') {
      const index = this.getSectionIdIndex(tr.sectionId)
      this.content.splice(index, 1, ...tr.result.content)
    } else if (tr.type === 'moveNode') {
      const content = [...this.content]
      const { fromIndex, toIndex } = tr
      const fromNode = content[fromIndex]
      content.splice(fromIndex, 1)
      content.splice(toIndex, 0, fromNode)
      this.content = content
    } else if (tr.type === 'removeSection') {
      // Start with this section
      const { sectionId } = tr
      const sectionIds = new Set<number>([sectionId])

      // If removing children
      if (tr.children) {
        const toc = new BlissbookToc(this)
        for (const item of toc.items) {
          if (item.chapterId === sectionId) {
            sectionIds.add(item.sectionId)
          }
        }
      }

      // Remove from sections
      for (const sectionId of sectionIds) {
        this.sectionsById.delete(sectionId)
      }

      // Remove from content
      this.content = this.content.filter((item) => {
        if (item.type === 'handbookSection') {
          const { sectionId } = item.attrs
          return !sectionIds.has(sectionId)
        }
        return true
      })
    }
    // Add a documentRef node to the handbook
    else if (tr.type === 'addDocumentRefs') {
      const { content, index } = tr.result
      this.insertContent(content, index)
    }
    // Remove a node from the handbook by id
    else if (tr.type === 'removeNode') {
      this.content = this.content.filter((node) => !isNodeId(node, tr.nodeId))
    } else if ('sectionId' in tr) {
      const section = this.sectionsById.get(tr.sectionId)
      section.applyTransaction(tr)
    } else if (tr.type === 'setAcknowledgementAttrs') {
      Object.assign(this.acknowledgementForm, tr.value)
    } else if (tr.type === 'setAcknowledgementFragment') {
      this.acknowledgementForm[tr.key] = tr.value
    } else if (tr.type === 'setBrandingAttrs') {
      Object.assign(this.branding, tr.value)
    } else if (tr.type === 'setContactFragment') {
      this.contactFragments[tr.key] = tr.value
    } else if (tr.type === 'setSettings') {
      Object.assign(this, tr.result)
    }
  }

  private insertContent(content: Node[], index?: number) {
    if (index === undefined) {
      this.content.push(...content)
    } else {
      this.content.splice(index, 0, ...content)
    }
  }

  can(permissionId: string) {
    return this.permissionIds.includes(permissionId)
  }

  get path() {
    return `/handbooks/${this.id}`
  }

  // Find the index of this section
  getSectionIdIndex(sectionId: number) {
    return this.content.findIndex((node) => {
      if (node.type === 'handbookSection') {
        return node.attrs.sectionId === sectionId
      }
    })
  }

  // Determine that path to the handbook preview, with optional bookmark
  getPreviewUrl(hash?: string) {
    let url = this.path + '/preview'
    if (hash) url += hash
    return url
  }
}

// @ts-ignore: immerable
HandbookDataModel[immerable] = true
